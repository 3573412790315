.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #b966e7;
    border-bottom-color: #512a95;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin:200px 200px;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    .CategoryTitle{
        font-size: 25px;
    }

    .CategoryTitle Link:hover{
        text-decoration: none;
        color:red !important;
    }

    @media only screen and (max-width: 575px) {
        .userDerp{
            margin-left: 75px !important;
          }
          .message-btn {
         
            padding: 15px 72px !important;
          
          }
          .popup-box {
            width: 310px !important;
          }
      }
  

      /* Sticky Button */
.sticky-message-button {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    z-index: 9999; /* Ensure the button stays above other content */
  }
  
  .message-btn {
    background-image: linear-gradient(to right, var(--color-secondary), var(--color-primary)); /* Gradient background */
    color: white; /* Text color */
    padding: 15px 95px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .message-btn:hover {
    background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary)); /* Reverse gradient on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000; /* Above the button */
  }
  
  /* Popup box */
  .popup-box {
    position: absolute;
    top: calc(30vh - 25%); /* Ensures the height starts where the button ends */
    left: 20px;
    width: 350px;
     height: 80vh;
     background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
   }
  
  /* Popup header */
  .popup-header {
    /* margin-bottom: 20px; */
    font-size: 18px;
    font-weight: regular;
    color: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  }

  .popup-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10002; /* Above everything in the popup */
    /* background: transparent; */
    border: none;
    font-size: 15px;
    cursor: pointer;
  }
  
  
  /* Popup body */
  .popup-body p {
    font-size: 16px;
    color: #666;
  }